import ButtonContainer from "./style";
import React from "react";


const Button = (props) => {
    return (<ButtonContainer {...props}>
        {props.children}
    </ButtonContainer>)
}
export default Button
