import styled from 'styled-components'

const InputContainer = styled.div`
  background: transparent;
  box-sizing: border-box;
  padding: 20px 0 0 0;
  position: relative; 
  margin-bottom: 5px;
  width: 100%;
  transition: all 300ms ease-in;
  
  & > label {
    position: absolute;
    color: #fff;
    font-size: 14pt;
    top: 25px;
    left: 0;
    transition: all 200ms linear;
  }
  
  & > label.toUp {
    top: 8px !important;
    font-size: 11pt;
    transition: all 200ms ease-in-out;
  }
  
  
  & > input {
    z-index: 1;
    font-size: 14pt;
    outline: none;
    color: #fff;
    background: transparent;
    width: 100%;
    height: 100%;
    padding: 5px 5px 1px 1px;
    box-sizing: border-box;
    border: none;
    border-bottom: 1px solid #ccc;
    min-height: 40px;
  }
`


export default InputContainer;
