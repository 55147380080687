import Input from "../../../Input";
import {
    EMAILJS_SERVICE_ID,
    EMAILJS_TEMPLATE_ID, EMAILJS_USERID,
    EmailPattern,
    NamePattern,
    PhonePattern
} from "../../../../core/settings";
import Button from "../../../Button";
import {BsArrowRight} from "react-icons/all";
import React, {useEffect} from "react";
import {useForm} from "react-hook-form";
import {toast} from 'react-toastify';
import emailjs from "emailjs-com";

const FormSender = (props) => {
    const initialValues = {
        name: '',
        email: '',
        message: '',
        phone: ''
    }

    const {register, handleSubmit, formState} = useForm({mode: "onChange", defaultValues: initialValues})
    const onSubmit = (data) => {
        if (formState.isValid) {
            emailjs.send(EMAILJS_SERVICE_ID, EMAILJS_TEMPLATE_ID, {
                name: data.name,
                email: data.email,
                phone: data.phone,
                message: data.message,
            }, EMAILJS_USERID).then((response) => {
                toast.success("Boa! Tudo certo. Seu email foi enviado com sucesso. Vou te responder em 1000ms. HAHA ;D")
            }, (rejected) => {
                toast.error("Oops! Eita rapaz, não foi possível enviar o email agora! Mas não fique nervoso, utilize os outros diversos meios para me contatar. ;D")
            });
        }
    }
    useEffect(() => {
        let errors = formState.errors
        if (errors.name)
            toast(errors.name.message)
        else if (errors.email) {
            toast(errors.email.message)
        } else if (errors.message)
            toast(errors.message.message)


    }, [formState])

    return (<form onSubmit={handleSubmit(onSubmit)}>
        <Input labeltext={"Seu Nome"} labelfor={"name"} {...register("name", {
            required: "Digite o seu nome, de preferência completo para continuar.",
            pattern: NamePattern
        })}/>
        <Input labeltext={"Seu Telefone"} labelfor={"phone"}
               type={'tel'} {...register("phone", {pattern: PhonePattern})}/>
        <Input labeltext={"Seu Email"} labelfor={"email"} {...register("email", {
            required: "Por gentileza, informe o seu endereço de email para que eu possa de contactar!! :)",
            pattern: {
                value: EmailPattern,
                message: "O endereço de email não é válido, forneça uma válido para eu falar com você ;)"
            }
        })} />
        <Input labeltext={"Mensagem"} labelfor={"message"} {...register("message",
            {required: "Diga o que deseja de forma breve, caso queira enviar uma mensagem detalhada, envie um email ou inicie uma conversa no telegram :)"})}/>

        <Button type={"submit"} name={'send'}>Enviar Mensagem <BsArrowRight/></Button>
    </form>)
}

export default FormSender
