/*
  * Component: contact
  * Author: Kevin F M Santana
  * Date: 05/02/2022 - 00:07
  * Version: 1.0.0A
  * @patch-notes - (Change Log)
  *  - 1.0.0A
  *  () O componente contact.js foi criado
  */

import GlobalLayout from "../layout/global";
import React from "react";
import styled from "styled-components"
import {AiFillMail, BsTelegram, RiWhatsappFill} from "react-icons/all";
import {EuDefaultImg} from "../components/pages/home.styles";
import Fade from "react-reveal/Fade";
import withReveal from "react-reveal/withReveal";
import FormSender from "../components/pages/contact/FormSender";

const MainContact = withReveal(styled.div`
  display: flex;
  flex-direction: row;
  justify-content: stretch;
  align-items: stretch;
  width: 100%;
  height: 100%;
  font-family: ZenKurenaido-Regular, sans-serif;
  color: #fff;
  padding: 50px;
  box-sizing: border-box;

  @media (max-width: 500px) {
    padding: 10px;
  }

  @media (max-width: 800px) {
    flex-direction: column !important;
  }

  @media (min-height: 450px) {
    padding: 0 50px;
  }

`, <Fade delay={400}/>)
const ContactDetails = (props) => {

    const ContactReveal = styled.div`
      width: 100%;
      height: 100%;
      padding: 25px;
      text-align: center;

      & > .description {
        margin: 15px;
      }

      & > p, a, h3 {
        margin: 15px 0;
      }

      & > h3 {
        color: #fff;
        font-weight: bold;
      }

      & > a {
        color: #fff;
      }

      & > .contactOption {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 16px;

        & > a, h3 {
          margin: 0;
          color: #fff;

        }

        & > a {
          cursor: pointer;
        }
      }

      & > h1 {
        text-transform: uppercase;
        font-size: 2em;
        text-align: center;
      }

      @media (max-width: 500px) {
        padding: 0;
        margin-bottom: 40px;
        & > .description {
          margin: 0;
          text-align: justify;
        }
      }
    `

    return (
        <ContactReveal {...props}>{props.children}</ContactReveal>
    )

}
const ContactForm = (props) => {
    const FormReveal = styled.div`
      width: 100%;
      height: 100%;
      box-shadow: -9px 4px 20px rgb(0 0 0 / 52%);
      padding: 25px;
      box-sizing: border-box;

      & > form {
        width: inherit;
        height: inherit;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      & > h1 {
        text-transform: uppercase;
        font-size: 2em;
        text-align: center;
        margin-bottom: 5px;
      }

      @media (max-width: 500px) {
        & > h1 {
          font-size: 1.3em !important;

        }
      }
    `
    return (
        <FormReveal {...props} onSubmit={props.onSubmit}>{props.children}</FormReveal>
    )
}

const Contact = ({location}) => {
    return (<GlobalLayout pageTitle={"Contate Kevin Santana"} location={location}>
        <EuDefaultImg/>
        <MainContact>
            <ContactDetails>
                <h1>Contato</h1>
                <p className={'description'}>Você pode me contatar facilmente por diversos meios,
                    é fácil e eu responderei o mais rápido possível. Exceto em finais de semana e feriados.
                    Devido a uma quantidade cavalar de SPAMS eu removi o meu telefone de contato. Infelizmente,
                    é muito difícil eu atender uma chamada de números sem nome devido a esse inconveniente.
                </p>
                <div className={'contactOption'}>
                    <h3><AiFillMail/> Via Email</h3>
                    <a target={"_blank"} rel={"noreferrer"} href={"mailto:kevin@redcomet.com.br"}>Kevin Santana</a>
                </div>
                <div className={"contactOption"}>
                    <h3><BsTelegram/> Via Telegram</h3>
                    <a target={"_blank"} rel={"noreferrer"} href={"https://t.me/KevinFMSantana"}> Kevin F M Santana</a>
                </div>
                <div className={"contactOption"}>
                    <h3><RiWhatsappFill/> Via WhatsApp</h3>
                    <a onClick={() => {
                        window.open("https://api.whatsapp.com/send?1=pt_BR&phone=5571999543300&text=Ol%C3%A1%20desejo%20desejo%20contratar%20um%20de%20seus%20servi%C3%A7os.", "_blank")
                    }}> Kevin F M Santana</a>
                </div>


            </ContactDetails>
            <ContactForm>
                <h1>Formulário de Contato</h1>
                <FormSender/>
            </ContactForm>
        </MainContact>
    </GlobalLayout>)
}

export default Contact
