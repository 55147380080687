



export const NamePattern = /([a-z .ç'ãõá-úà-ùâ-û]+)/i
export const PhonePattern = /((\(?[0-9]{2}\)?)( ?[0-9]{1,1})( ?[0-9]{4}[- ]?[0-9]{4}))/
export const EmailPattern = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

export const EMAILJS_TEMPLATE_ID = "gm_tmp_kfms"
export const EMAILJS_SERVICE_ID = "gmail_kevinfnSantana"
export const EMAILJS_USERID= "user_2vPKJsc5NUZja7j42Lr6K"
