import styled from 'styled-components'

const ButtonContainer = styled.button`
  padding: 10px;
  color:#ccc;
  box-shadow: 4px 6px 10px rgba(0,0,0,.33);
  border: none;
  outline: none;
  font-family: ZenKurenaido-Regular, sans-serif;
  margin: 10px 0;
  text-align: center;
  background: transparent;
  font-size: 16pt;
  font-weight: bold;
  transition: all 200ms linear;
  width: 55%;
  position: relative;
  cursor: pointer;
  &:hover {
    color: gold;
    transition: all 200ms linear;
  }
  
  & > svg {
    position: absolute;
    top: 20px;
    padding-left: 20px;
  }
`
export default ButtonContainer
