import InputContainer from "./style";
import React, {useRef} from "react";


function EffectLabelFocus(ev, labelRef) {
    const label = labelRef.current
    const input = ev.target
    if(ev.type === 'focus'){
        label.classList.add("toUp")
    }
    else if(ev.type === "blur") {
        if(input.value === ""){
            label.classList.remove("toUp")
        }
    }
}

const ToForwardInput = (props, ref) =>{

    const labelRef = useRef(null)

    return <InputContainer {...props}>
        <label htmlFor={props.labelfor} ref={labelRef}>{props.labeltext}</label>
        <input autoComplete={props.autoComplete || 'off'} ref={ref} {...props} id={props.labelfor} onFocus={(e) => EffectLabelFocus(e, labelRef)} onBlur={(e) => EffectLabelFocus(e, labelRef)}/>
    </InputContainer>
}

const Input = React.forwardRef(ToForwardInput)

export default Input
